import React from 'react';
import Card from './Card';

const Affiliates = () => {
  const affiliates = [
    { title: "Affiliate Program A", description: "Earn commissions by referring users.", link: "https://example.com/affiliate-a" },
    { title: "Affiliate Program B", description: "Best rates and bonuses.", link: "https://example.com/affiliate-b" },
  ];

  return (
    <div>
      <h2>Affiliates</h2>
      <div className="cards-container">
        {affiliates.map((affiliate, index) => (
          <Card key={index} title={affiliate.title} description={affiliate.description} link={affiliate.link} />
        ))}
      </div>
    </div>
  );
};

export default Affiliates;

