import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';

import PropFirmInfo from './components/PropFirmInfo';
import Courses from './components/Courses';
import Affiliates from './components/Affiliates';
import WebApp from './telegram';

function App() {
  useEffect(() => {
    if (WebApp && WebApp.ready) {
      WebApp.ready(); // Notify Telegram the app is ready
    }

    // Access Telegram user data
    const user = WebApp?.initDataUnsafe?.user || null;
    if (user) {
      console.log(`Hello, ${user.first_name}`);
    }

    // Apply Telegram's theme
    const theme = WebApp?.colorScheme || 'light';
    document.body.className = theme;
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="app-header">
          <h1>Traders PUB</h1>
        </header>
       <nav className="navigation">
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
          >
            Prop Firms
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/courses"
            className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
          >
            Courses
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/affiliates"
            className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
          >
            Affiliates
          </NavLink>
        </li>
      </ul>
    </nav>

        <main>
          <Routes>
            <Route path="/" element={<PropFirmInfo />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/affiliates" element={<Affiliates />} />
          </Routes>
        </main>
        <footer className="app-footer">
          <button onClick={() => WebApp.close()}>Close</button> {/* Close button */}
          <p>© 2024 Traders PUB. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;

