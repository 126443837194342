import React from 'react';
import Card from './Card';

const Courses = () => {
  const courses = [
    { title: "Course A", description: "Learn trading basics.", link: "https://example.com/course-a" },
    { title: "Course B", description: "Advanced strategies.", link: "https://example.com/course-b" },
    { title: "Course C", description: "Crypto trading essentials.", link: "https://example.com/course-c" },
  ];

  return (
    <div>
      <h2>Courses</h2>
      <div className="cards-container">
        {courses.map((course, index) => (
          <Card key={index} title={course.title} description={course.description} link={course.link} />
        ))}
      </div>
    </div>
  );
};

export default Courses;

