import React from 'react';
import Card from './Card';

const PropFirmInfo = () => {
  const firms = [
    { title: "Firm A", description: "Description of Firm A.", link: "https://example.com" },
    { title: "Firm B", description: "Description of Firm B.", link: "https://example.com" },
    { title: "Firm C", description: "Description of Firm C.", link: "https://example.com" },
  ];

  return (
    <div>
      <h2>Prop Firms</h2>
      <div className="cards-container">
        {firms.map((firm, index) => (
          <Card key={index} title={firm.title} description={firm.description} link={firm.link} />
        ))}
      </div>
    </div>
  );
};

export default PropFirmInfo;
